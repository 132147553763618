.modal-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  backdrop-filter: blur(1.5px);
  z-index: 2000;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  top: 25rem;
}

.modal-content {
  position: fixed;
  width: 45rem;
  padding: 1rem;
  z-index: 2001;
  overflow: hidden;
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .modal {
    top: 35rem;
  }
  .modal-content {
    width: 55rem;
  }
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: -0.5rem -0.5rem 0.5rem 0;
}

.modal-header h4 {
  margin: 0;
}

.close-modal:hover {
  cursor: pointer;
}

.modal-body {
  padding: 0.625rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal-footer {
  padding: 0.625rem;
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  padding: 0.625rem;
  color: white;
}

.modal-content p {
  margin: 0.625rem 0 0 0;
  padding: 0;
}
