.top-wrapper {
  position: relative;
  overflow: hidden;
}

.page-frame {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100vw;
}

.background-img-1 {
  position: absolute;
  left: 6.25rem;
  top: 37.5rem;
  z-index: -1;
}

.background-img-12 {
  position: absolute;
  /* left: 6.25rem; */
  top: 5.5rem;
  z-index: -1;
}

.background-img-2 {
  position: absolute;
  left: 6.25rem;
  top: 140rem;
  z-index: -1;

  transform: scaleX(150%);
}
.background-img-3 {
  position: absolute;
  left: 8rem;
  top: 30rem;
  z-index: -1;
  opacity: 0.7;

  transform: scaleX(150%);
}
.background-img-4 {
  position: absolute;
  left: 6.25rem;
  top: 140rem;
  z-index: -1;

  transform: scaleX(150%);
}
.background-img-5 {
  position: absolute;
  left: 6.25rem;
  top: 140rem;
  z-index: -1;

  transform: scaleX(150%);
}

.card-frame {
  max-width: 65rem;
  width: auto;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: none;

  margin-top: 5.625rem;
  margin-bottom: 5.625rem;

  border-radius: var(--border-radius-10);
}
@media (max-width: 768px) {
  .card-frame {
    width: 98%;
  }
}

.card-frame:nth-of-type(1) {
  margin-top: 0rem;
}

.card-frame__subtitle {
  padding: 1rem 3.75rem;
}

.title {
  max-width: 70rem;
  padding: 1.56rem 3rem;
  margin-top: 8rem;
  margin-bottom: 4rem;

  text-align: center;

  font-family: 'Inter';
  color: var(--stak-black);
}

.title__main {
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 1.3em;
}

.title__sub {
  max-width: 80rem;
  margin-top: 5rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.3em;

  text-align: justify;

  color: var(--stak-dark-gray);
}
.title__sub-build {
  margin-top: -1rem;
  margin-bottom: 3rem;
}

.card-frame h2 {
  height: fit-content;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 1.3em;
  display: flex;
  align-items: center;
  text-align: center;

  color: white;
}

.card-frame h3 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.2em;

  text-align: left;

  color: white;
  margin-top: 2rem;
}
.card-frame li {
  margin-left: 2rem;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 1.3em;

  text-align: left;

  color: white;
  list-style-type: disc;
}

.example-invoice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.625rem;
  gap: 0.625rem;

  box-shadow: var(--tile-shadow);
  border-radius: var(--border-radius-10);
}

@media (max-width: 768px) {
  .example-invoice {
    width: 95%;
  }
}

.example-invoice__img {
  width: fit-content;
  width: 43.75rem;
  height: 25rem;
  border-radius: var(--border-radius-10);
  box-shadow: var(--tile-shadow);

  margin: 0 1rem 0 0;
}

@media (max-width: 1200px) {
  .example-invoice__img {
    height: 300px;
  }
}
@media (max-width: 960px) {
  .example-invoice__img {
    height: 250px;
  }
}
@media (max-width: 768px) {
  .example-invoice__img {
    height: 175px;
  }
}
@media (max-width: 500px) {
  .example-invoice__img {
    height: 140px;
  }
}

.example-invoice__text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 0.625rem;

  width: 24.5rem;
  height: fit-content;

  /* background: var(--stak-dark-gray); */
  color: var(--stak-dark-gray);
  padding: 1.25rem 1.25rem;
  margin: 0.5rem 0;

  border-radius: var(--border-radius-10);

  flex: none;
  order: 0;
  flex-grow: 0;

  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

@keyframes shiftUp {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animate {
  animation: shiftUp 1s ease-in-out forwards;
}

@keyframes shiftUpCard {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animate-card {
  animation: shiftUpCard 1s ease-in-out forwards;
}

@keyframes shiftRight {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
    transform: translateY(-50%);
  }
}

.animate-tt-right {
  animation: shiftRight 1s ease-in-out forwards;
}

@keyframes shiftLeft {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
    transform: translate(0%, -50%);
  }
}

.animate-tt-left {
  animation: shiftLeft 1s ease-in-out forwards;
}

@keyframes shiftLeftMiddle {
  from {
    opacity: 0;
    transform: translate(-20%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0%, -50%);
  }
}

.animate-tt-left-middle {
  animation: shiftLeftMiddle 1s ease-in-out forwards;
}
@keyframes shiftRightMiddle {
  from {
    opacity: 0;
    transform: translate(20%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0%, -50%);
  }
}

.animate-tt-right-middle {
  animation: shiftRightMiddle 1s ease-in-out forwards;
}
