.process-invoice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0 1rem 0;
  gap: 0.875rem;

  width: 48rem;

  background-color: var(--stak-white-light);
  border-radius: var(--border-radius-12);
  box-shadow: var(--tile-shadow);
}

.process-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.footer-sliders {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-self: flex-start;
}

.button {
  margin-right: 7rem;
  font-size: 1.5rem;
  padding: 1rem 3rem;
}
