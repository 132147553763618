.links-parent-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.25rem 0;

  width: fit-content;
  height: auto;

  background: var(--stak-white-light);
  box-shadow: var(--tile-shadow);
  border-radius: var(--border-radius-10);

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  position: relative;
}

.links-scroll-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;

  width: 100%;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  overflow-y: scroll;
}

.links-content-frame {
  padding: 0 1.25rem;
  gap: 1rem;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  flex: none;
  order: 0;
  flex-grow: 0;

  width: 100%;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.13rem;
  line-height: 1.6rem;
  color: var(--stak-dark-gray);
}

.links-content-frame li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  height: fit-content;
  max-width: 13rem;
  overflow-wrap: break-word;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;

  transition: 0.1s ease-in-out;
}

@media (max-width: 768px) {
  .links-content-frame li {
    font-size: 14px;
  }
}

.links-content-frame li:hover,
.links-content-frame li:active {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5));
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
