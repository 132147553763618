.build-invoice {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: fit-content;

  background-color: var(--stak-white-light);
  border-radius: var(--border-radius-12);
  box-shadow: var(--tile-shadow);

  background-color: var(--stak-white-light);
}

.scroll-frame {
  border-radius: var(--border-radius-12);
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  margin: 0;

  max-height: 43.75rem;

  flex-grow: 1;
  align-self: stretch;

  overflow-y: scroll;
  scroll-behavior: auto;
}

.example-invoice__img {
  width: 38rem;
  height: 45rem;
}

@media (max-width: 768px) {
  .example-invoice__img {
    height: 500px;
  }
}
@media (max-width: 960px) {
  .example-invoice__img {
    height: 600px;
  }
}
