.legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.563rem;
  margin-bottom: 1.5rem;
  gap: 4rem;

  background: var(--stak-white-light);
  border-radius: var(--border-radius-10);
}

.legend__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 0.625rem;

  font-weight: 600;
  font-size: 1.1rem;
  color: var(--stak-dark-gray);
}

.legend__bar-actuals {
  width: 7.2rem;
  height: 1.25rem;
  background: var(--stak-green);
}
.legend__bar-budget {
  width: 7.2rem;
  height: 1.25rem;
  background: var(--stak-blue);
}
