.scroll-into-view-title {
  animation: shiftUp 1s ease-in-out forwards;
}

.scroll-into-view-text {
  animation: shiftUpText 0.75s 0.5s ease-in-out forwards;
}

.scroll-into-view-button {
  animation: shiftUp 1s 1s ease-in-out forwards;
}

.scroll-into-view {
  animation: shiftUp 1s ease-in-out forwards;
}

@keyframes shiftUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes shiftUpText {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade-into-view {
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.svg-fade-in {
  animation: svgFadeIn 1.5s ease-in-out forwards;
}
.svg-fade-in-bottom {
  animation: svgFadeIn2 1.5s ease-in-out forwards;
}

.svg-fade-in-left {
  animation: svgFadeInLeft 1.75s ease-in-out forwards;
}

@keyframes svgFadeIn {
  0% {
    opacity: 0;
    transform: translate(100%, 100%) rotate(180deg) scale(0);
  }

  75% {
    opacity: 1;
    transform: translate(-5%, 0%) rotate(-10deg) scale(1.15);
  }
  100% {
    opacity: 1;
    transform: translate(-5%, 0%) rotate(0deg) scale(1);
  }
}
@keyframes svgFadeIn2 {
  0% {
    opacity: 0;
    transform: translate(100%, 100%) rotate(-180deg) scale(0);
  }

  75% {
    opacity: 1;
    transform: translate(-25%, -22%) rotate(8deg) scale(0.8);
  }
  100% {
    opacity: 0.6;
    transform: translate(-25%, -22%) rotate(10deg) scale(0.75);
  }
}

@keyframes svgFadeInLeft {
  from {
    opacity: 0;
    transform: translate(-100%, 100%) rotate(120deg);
  }

  to {
    opacity: 0.6;
    transform: translate(10%, 2%) rotate(-14deg);
  }
}
