.button {
  width: fit-content;
  font-size: 1em;
  background-color: var(--stak-dark-green);
  border: none;
  color: white;
  padding: 0.7em 2em;
  border-radius: var(--border-radius-50);
  transition: 0.1s ease-in-out;

  font-family: 'Open Sans';
  font-style: normal;
}

.button:hover,
.button:active {
  cursor: pointer;
  background-color: var(--stak-dark-green-hover);
  border: none;
  box-shadow: var(--tile-shadow);
}
