@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
* {
  box-sizing: border-box;
}

:root {
  /* --stak-black: #313031; */
  --stak-black: #212529;
  --stak-dark-gray: #656565;
  --stak-light-gray: #9d9d9d;
  --stak-blue: #4285f4;
  --stak-blue-hover: #0094ff;
  --stak-bluegreen: #44aacb;
  --stak-yellow: #d2bf64;
  --stak-dark-yellow: #df9920;
  --stak-neongreen: #21ffd0;
  --stak-white-light: #fff;
  --stak-green: #5bc572;
  --stak-green-50: #5bc57280;
  --stak-dark-green: #5e898a;
  --stak-dark-green-hover: #437677;
  --stak-light-blue: rgba(88, 139, 222, 0.6);
  --border-radius-12: 0.75rem;
  --border-radius-10: 0.625rem;
  --border-radius-50: 3.125rem;
  --tile-shadow: 0 12px 32px rgba(0, 0, 0, 0.2);
  --main-bg: #eee;
}

html {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 0.8vw !important;
  font-style: normal !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

@media (max-width: 1200px) {
  /* html {
    font-size: 12px !important;
  } */
}

@media (max-width: 968px) {
  /* html {
    font-size: 10px !important;
  } */
}

@media (max-width: 720px) {
  html {
    font-size: 1.5vw !important;
  }
}

/* @media (max-width: 500px) {
  html {
    font-size: 6px !important;
  }
} */

body {
  margin: 0;
  background-color: var(--main-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

ul {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}
