.main-hero-frame {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 8rem;
  padding: 3.5rem 15rem;
  gap: 3rem;
}

.headline {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  flex: 1;
  font-family: 'Inter';
}

.headline h1 {
  font-weight: 500;
  font-size: 7.375rem;
  opacity: 0;
  animation: fadeUpIn 1s ease-in-out forwards;
}
.headline h2 {
  font-weight: 500;
  font-size: 2.9rem;
  opacity: 0;
  animation: fadeUpIn 1s ease-in-out forwards;
  animation-delay: 0.25s;
}
.headline h3 {
  font-weight: 400;
  font-size: 2.2rem;
  opacity: 0;
  animation: fadeUpIn 1s ease-in-out forwards;
  animation-delay: 0.5s;
}

.hero-image {
  background: url('../../assets/hero/modern-house-hero2.jpg') center/100%
    no-repeat;
  background-size: cover;
  width: 25rem;
  height: 45rem;
  flex: 1;
  border-radius: 0 22rem 22rem 22rem;

  box-shadow: var(--tile-shadow);
  opacity: 0;
  animation: hero-image 1.1s ease-in-out forwards;
}

.main-hero-frame img {
  max-width: 100%;
  max-height: 100%;
}

.ellipse__top {
  position: absolute;
  left: 7rem;
  top: 4rem;
  z-index: -1;

  opacity: 0;
  animation: fadeIn 0.75s ease-in-out forwards;
}

.ellipse__bottomLeft {
  position: absolute;
  top: 22rem;
  left: 5rem;
  z-index: -1;

  opacity: 0;
  animation: fadeIn 0.75s ease-in-out forwards;
  animation-delay: 0.25s;
}
.ellipse__bottomRight {
  position: absolute;
  top: 10rem;
  left: 15rem;
  z-index: -1;
  opacity: 0;
  animation: fadeIn 0.75s ease-in-out forwards;
  animation-delay: 0.25s;
}

@keyframes fadeUpIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hero-image {
  0% {
    opacity: 0;
    transform: translate(50%, 25%) scale(0.5) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translate(10%, 0%) scale(1.1) rotate(5deg);
  }

  100% {
    opacity: 1;
    transform: translate(10%, 0%) scale(1) rotate(0deg);
  }
}

@media (max-width: 768px) {
  .main-hero-frame {
    padding: 3.5rem 3rem;
  }
  .headline h1 {
    font-size: 6rem;
  }
  .headline h2 {
    font-size: 2rem;
  }
  .headline h3 {
    font-size: 1.8rem;
  }
  .hero-image {
    height: 35rem;
  }
  .ellipse__top {
    left: 1rem;
    top: -2rem;
  }
  .ellipse__bottomLeft {
    left: 0rem;
    top: 10rem;
  }
  .ellipse__bottomRight {
    left: 7rem;
    top: 0rem;
  }
}
