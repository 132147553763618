.title {
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.25rem;
  gap: 0.25rem;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-weight: 600;
  font-size: 1.35rem;
  line-height: 1.88rem;
}
