.approver {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  margin-right: 0.5rem;
  background: var(--stak-dark-green-hover);

  border-radius: 36px;

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: var(--stak-white-light);
}

.add {
  padding: 0.5rem 1rem;
  align-self: center;
  text-align: center;
}
