.navbar-main {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  padding: 1.2rem 0 !important;
}

.navbar-main a {
  text-decoration: none;
}

.hidden {
  opacity: 0;
  animation: fadeOut 0.15s ease-in forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.show {
  animation: fadeIn 0.15s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1 !important;
  }
}

.name {
  display: flex;
  align-items: center;
}
.name h3 {
  display: inline-block;
  margin: auto 0.625rem;
}

.logo-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: 0.1s ease-in-out;
}

.logo-container:hover {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.75));
  cursor: pointer;
}

.logo-container p {
  margin: 0 0 0 0.3em;
  color: white;
  font-size: 2.5em;
}

.logo {
  width: 3em;
  height: 3em;
}

.nav-link-container {
  margin-left: auto;
  font-size: 1.5rem;
}

.nav-link-container a,
.nav-link-container button {
  color: white;
  margin: 0 1.5em;
}

.nav-link-container div {
  background-color: var(--bs-dark);
}

.nav-dropdown div a {
  font-family: 'Open Sans', sans-serif;
  display: block;
  font-size: 1.4rem;
  margin: 0;
  padding-left: 2.5rem;
}

.nav-dropdown div a:hover,
.nav-dropdown div a:active {
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
  background: None;
}

.active-feature-page {
  text-shadow: 0 0px 4px rgba(255, 255, 255, 1);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .nav-link-container {
    font-size: 2.25rem;
    gap: 8px;
    margin-top: 8px;
  }
  .logo-container {
    font-size: 1.5rem;
  }
  .nav-dropdown div a {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .nav-link-container {
    font-size: 2.25rem;
    gap: 8px;
    margin-top: 8px;
  }
  .logo-container {
    font-size: 1.5rem;
  }
  .nav-dropdown div a {
    font-size: 2rem;
  }
}

.active-home-page {
  text-shadow: None;
  text-decoration: None;
}

.nav-link-container div a:hover {
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
}

.contact-button {
  background-color: var(--stak-black);
  font-weight: 500;
  border: 1px solid white;
  border-radius: 6px;
  transition: 0.15s ease-in-out;
  padding: 0 0.5em;
  width: fit-content;
}

.contact-button:hover,
.contact-button:active {
  color: black !important;
  background-color: white;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.nav-link-container a:hover {
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
  color: white;
}
