.b2a-parent {
  display: flex;
  justify-content: center;
  padding: 0;
  gap: 1.25rem;

  width: 60rem;
  height: 40rem;
}

.budget-inputs-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 1.25rem;

  width: 100%;
  height: 41.25rem;

  /* want this card to overlay drop shadow from title card */
  position: relative;
  z-index: 1;
}
