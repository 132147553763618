/* Custom properties */
:root {
  --tooltip-text-color: var(--stak-dark-gray);
  --tooltip-background-color: var(--stak-white-light);
  --tooltip-margin: 1.3rem;
  --tooltip-arrow-size: 1rem;
}

.wrapper-scroll {
  overflow-y: scroll;
  align-self: stretch;
}

.tooltip-tip {
  filter: drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.2));
  border-radius: var(--border-radius-10);

  width: 22rem;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.625rem 1rem;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);

  font-weight: 400;
  font-size: 1.25rem;

  z-index: 100;

  text-align: left;
}

@media (max-width: 768px) {
  .tooltip-tip {
    visibility: hidden;
  }
}

.tooltip-tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -0.7);
}

/* Absolute positioning */
.tooltip-tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.tooltip-tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltip-tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltip-tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltip-tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltip-tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltip-tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(-5%) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}
