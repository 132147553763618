.toggle-buttons-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0.25rem 0px 0.25rem 0.25rem;
  gap: 0.25rem;

  flex: none;
  order: 1;
  flex-grow: 0;
  align-self: stretch;
}

.toggle-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0;
  gap: 0.25rem;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.toggle-buttons svg:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.25));
}
