.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 0.75rem;
}

.text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 0.75rem;
}

.input-container label {
  font-family: 'Open Sans';
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0.5rem;
  flex: 1;

  color: var(--stak-dark-gray);
}

.input-container input,
.input-container textarea {
  border: none;
  border-radius: var(--border-radius-10);
  flex: 2;
  padding: 0.75em 0.75em;

  background-color: var(--main-bg);
}

@media (max-width: 768px) {
  .input-container label {
    font-size: 2rem;
  }
  .input-container input,
  .input-container textarea {
    padding: 1.5rem 2rem;
  }
  .input-container input::placeholder,
  .input-container textarea::placeholder {
    color: rgb(180, 180, 180);
    font-size: 1.5rem;
  }
}

.input-container input::placeholder,
.input-container textarea::placeholder {
  color: rgb(180, 180, 180);
  font-size: 1.2rem;
}

.invalid {
  box-shadow: 0 0 3px red;
}
