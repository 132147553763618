.plots-parent-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 0 1.25rem 1.25rem;

  height: auto;
  width: 48rem;

  background: var(--stak-white-light);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-10);

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;

  font-family: 'Open Sans';
  color: var(--stak-dark-gray);

  position: relative;
}

.scroll-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 3.125rem;

  width: 100%;
  overflow-y: scroll;
}

.scroll-frame .svg-style {
  border-radius: var(--border-radius-10);
  height: 100%;
  width: 100%;
}

.svg {
  height: 100%;
  border-radius: var(--border-radius-10);
}
