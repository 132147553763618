.project-budget-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 4rem;
  gap: 1.25rem;

  position: relative;
  z-index: 0;
}

.budget-inputs-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 1.25rem;

  width: 100%;
  height: 41.25rem;

  /* want this card to overlay drop shadow from title card */
  position: relative;
  z-index: 1;
}

@media (max-width: 968px) {
  .project-budget-parent {
    font-size: 14px;
  }
}

@keyframes shiftUp {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animate {
  animation: shiftUp 1s ease-in-out forwards;
}
