.input-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  gap: 0.25rem;
  width: 100%;
  flex-grow: 1;
}

.label {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 1.125rem;
  color: var(--stak-dark-gray);

  padding: 0 1rem;
  width: 12.5rem;

  flex: none;
  order: 0;
  align-self: stretch;
}
