.footer-main {
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9.375rem;

  padding: 7rem 0 2.5rem;
  margin-top: 3rem;

  color: white;
  background-color: var(--stak-black);

  overflow: hidden;
}

@media (max-width: 720px) {
  .footer-main {
    gap: 7rem;
  }
}

.upper-container {
  display: flex;
  align-items: center;
  gap: 11.44rem;
}

.logo {
  transition: 0.15s ease-in-out;
}

.logo:hover {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.75));
  cursor: pointer;
}

@media (max-width: 600px) {
  .upper-container {
    gap: 6rem;
  }
}

.svg-icon {
  width: 1000;
  height: 59;
}

.get-in-touch-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.625rem;
}

.get-in-touch-box h4 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.063rem;
  color: white;
}

.get-in-touch-box section {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: 0.15s ease-in-out;
}

.get-in-touch-box section h5 {
  display: inline;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: white;

  padding-left: 0.5rem;
  margin-bottom: 0.125rem;
}

.get-in-touch-box .svg {
  margin-left: 0.1rem;
  fill: white;
  transition: 0.15s ease-in-out;
}
.get-in-touch-box .svg:hover {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.75));
  cursor: pointer;
  transform: translateY(-10%);
}

.get-in-touch-box section:hover {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.75));
  cursor: pointer;
}

.message-icon:hover {
  color: black;
}

.copyright {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
  min-width: 200px;
}
