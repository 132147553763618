.form-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.button {
  font-size: 1.8rem;
  padding: 0.5rem 2.5rem;
  border-radius: 1.8rem;
}

.spinner-container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.5rem;
}

.spinner-container p {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.5rem;

  font-size: 1.2rem;
  font-weight: 500;
  color: red;
}

.spinner-container span {
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
}

.invalid {
  background-color: rgb(240, 146, 146);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
