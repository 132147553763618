.title-card {
  width: 100%;
  background: var(--stak-white-light);
  box-shadow: var(--tile-shadow);
  border-radius: var(--border-radius-10);

  display: flex;
  align-items: center;
  padding: 1.125rem 1.25rem;

  justify-content: space-between;
}

/* Text Section */
.title-card__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
}

.title-card__text h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 2.875rem;
  color: var(--stak-dark-gray);
}

.title-card__subtitle {
  display: flex;
  align-items: center;

  flex: none;
  order: 1;
  flex-grow: 0;

  position: relative;
}

.title-card__subtitle h3 {
  display: inline;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.06rem;
  color: var(--stak-dark-gray);

  margin: 0;
}

/* Button Section */
.title-card__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  position: relative;
}

.title-card__buttons button {
  width: 16.375rem;
  height: 2.75rem;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;

  color: var(--stak-white-light);

  padding: 0px;
}
