.input-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  gap: 0.25rem;
  width: 100%;
  flex-grow: 1;
}

.label {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 1.125rem;
  color: var(--stak-dark-gray);

  padding: 0 1rem;
  width: 12.5rem;

  flex: none;
  order: 0;
  align-self: stretch;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 1rem;
  gap: 4px;

  background: var(--stak-white-light);
  border: 1.5px solid var(--stak-dark-gray);
  border-radius: 0.625rem;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 6;
}
