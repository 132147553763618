.plot-tile-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0 3rem;
  gap: 3.125rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  border-radius: var(--border-radius-10);
}

.plot-parent-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 95%;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.plot-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;

  width: 100%;
  height: 1.563rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  color: var(--stak-dark-gray);
  font-weight: 600;
}

.plot-title span {
  font-weight: 600;
}

.plot-tile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 0.625rem;

  width: auto;

  background: var(--stak-white-light);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-10);

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}
