.input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0;
  gap: 0.25rem;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.label {
  display: flex;
  padding: 0;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-weight: 600;
  font-size: 1.13rem;
  line-height: 1.5rem;
}

.input {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.5rem 0.4rem 1rem;
  gap: 0.25rem;

  background: var(--stak-white-light);
  border: 1.5px solid var(--stak-dark-gray);
  border-radius: var(--border-radius-10);

  color: var(--stak-dark-gray);
  font-weight: 600;
  font-size: 1.13rem;

  flex: none;
  order: 0;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .input {
    border-radius: 4px;
  }
}

.hidden-label {
  text-decoration-line: line-through;
}
