.line-item-box {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0;
  margin: 1rem 0;
  gap: 0.875rem;

  width: 100%;

  border-top: 1px solid var(--stak-dark-gray);
  border-bottom: 1px solid var(--stak-dark-gray);
}

.button {
  padding: 0.2rem 1.25rem;
  margin: 1rem 1.25rem;
  font-weight: 400;
  font-size: 2rem;
}
