.item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  gap: 0.25rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.item-title__text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  margin: 0;
  gap: 0.25rem;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-weight: 600;
  font-size: 1.125rem;

  color: var(--stak-dark-gray);
}

.line-sep {
  flex: none;
  order: 1;
  flex-grow: 1;

  border: 1px solid var(--stak-dark-gray);

  width: auto;
}
