.title {
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  padding: 1.875rem;
}

.title h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 4rem;
}

.headshots-frame {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin: auto;
  width: 60.5rem;
}

.headshot-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.563rem;

  flex: none;
  order: 0;
  flex-grow: 1;

  width: 21.875rem;

  transition: 0.15s ease-in-out;

  font-family: 'Open Sans';
  font-style: normal;
}

.headshot-box h3 {
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
}

.headshot-box h4 {
  font-weight: 400;
  text-align: center;
}

.headshot-box__img-left {
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 12.5rem;
  background-image: url('../../assets/headshots/headshot_michael.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  transition: 0.2s ease-in-out;
}

.headshot-box__img-right {
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 12.5rem;
  background-color: black;
  background-image: url('../../assets/headshots/Colin\ anderson_small-resized.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-position-x: 0.625rem;

  transition: 0.2s ease-in-out;
}

.headshot-box__img-right:hover {
  transform: translateY(-3%);
  cursor: pointer;
}

.headshot-box__img-left:hover {
  transform: translateY(-3%);
  cursor: pointer;
}

.scroll-into-view {
  animation: shiftUp 1s ease-in-out;
}

@keyframes shiftUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade-into-view {
  animation: fadeIn 2.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
