.main {
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 5rem 0 -4.5rem 0;

  color: var(--stak-dark-gray);
  background: linear-gradient(180deg, #588bde 0%, #656565 100%);
  background: var(--stak-black);

  font-weight: 600;
}

.main h2 {
  text-align: center;
  font-size: 3rem;
  margin: 4rem 0rem 0rem;
  padding: 0rem 1.875rem;
  color: var(--stak-white-light);
}

.main span {
  transition: 0.1s ease-in-out;
}

.main span:hover {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.75));
  cursor: pointer;
}
