.card-frame {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60.5rem;
  height: 27.6rem;
  margin-top: 5.625rem;
  overflow: hidden;
  border: none;
}

.feature-title {
  text-align: center;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 2.25rem;

  padding-top: 3rem;
  margin: 0;

  color: var(--stak-dark-gray);
}

.body-frame {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 34.2rem;

  margin-top: 2.8rem;
  margin-left: 3.4rem;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.1rem;

  color: var(--stak-dark-gray);

  opacity: 0;
}

.body-frame li {
  list-style: inside;
}

.body-frame p {
  display: block;
  margin: 0;
}

.body-frame__two {
  margin-top: 4.4rem;
  margin-left: 25rem;
}

.button__main {
  padding: 0.25rem 2.5rem;
  height: 3.75rem;

  font-size: 2rem;

  border-radius: 1.8rem;
  opacity: 0;
}

.button__first {
  margin: 2.5rem 0 0 7.7rem;
}

.button__two {
  margin: 1.1rem 0 0 34rem;
}

.button__three {
  margin: 3rem 0 0 9.5rem;
}

.background-image-svg-1 {
  position: absolute;
  left: 36.875rem;
  top: 8.125rem;

  margin: 0;
  padding: 0;
}

.background-image-svg-2 {
  position: absolute;
  left: -30rem;
  top: 12.5rem;
  transform: rotate(-11.31deg);
  opacity: 0.9;

  margin: 0;
  padding: 0;
}

@media (max-width: 960px) {
  .background-image-svg-2 {
    left: -48.313rem;
  }
}

.background-image-svg-3 {
  position: absolute;
  left: 35.625rem;
  top: 3.125rem;
  transform: rotate(16deg);
  opacity: 0.9;

  margin: 0;
  padding: 0;
}

.scroll-into-view {
  animation: shiftUp 3s ease-in-out forwards;
}

@keyframes shiftUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 720px) {
  .card-frame {
    align-items: center;
  }
  .body-frame,
  .body-frame__two {
    width: 70%;
    font-size: 1.75rem;
    margin-left: 0;
  }
  .body-frame ul {
    padding-top: 1rem;
    padding-left: 4rem;
  }
  .button,
  .button__two {
    margin: 2.5rem 0 3.125rem 0;
    width: 40%;
  }
  .body-frame svg {
    opacity: 0;
  }
  .background-image-svg-1,
  .background-image-svg-2,
  .background-image-svg-1 {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  .button__first {
    width: 40%;
    margin: 2.5rem 0 0 0rem;
  }
  .button__three {
    width: 40%;
    margin: 3rem 0 0 0rem;
  }
}
@media (max-width: 768px) {
}
