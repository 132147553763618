.wrapper-frame {
  display: flex;
  flex-direction: column;

  gap: 5rem;

  width: 48rem;

  position: relative;
}

.svg {
  height: 100%;
  border-radius: var(--border-radius-10);
}
