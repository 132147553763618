.hero {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.hero-inner {
  margin: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: url('../../assets/hero/sunny_day_hero.jpg') repeat center center
    fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hero-inner h1 {
  font-size: 10rem;
  color: white;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  animation: fadeUpIn 1s ease-in-out;
}

@keyframes fadeUpIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hero-inner h2 {
  font-size: 4rem;
  font-weight: 400;

  color: white;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  animation: fadeUpIn 1s ease-in-out;
  padding-bottom: 1.25rem;
}
