.budget-parent-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.25rem 0 1.25rem 1.25rem;

  height: auto;
  width: auto;

  background: var(--stak-white-light);
  box-shadow: var(--tile-shadow);
  border-radius: var(--border-radius-10);

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;

  font-family: 'Open Sans';
  color: var(--stak-dark-gray);
}

.total {
  margin: 0 auto 1rem auto;
  padding: 0.25rem 1rem;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.5rem;

  color: var(--stak-white-light);
  background-color: var(--stak-dark-gray);
  border-radius: var(--border-radius-50);

  box-shadow: var(--tile-shadow);
}

.budget-scroll-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1.25rem 0 0;

  width: 100%;
  height: 100%;

  overflow-y: scroll;

  position: relative;
}

.budget-content-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 1rem;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.division-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.5rem;
  gap: 0.5rem;

  width: 100%;
}

.division-frame__division {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: none;
  flex-grow: 1;

  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.5rem;
}

.division-frame__subdivision {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1rem 0px 1rem 1rem;
  gap: 0.25rem;

  width: auto;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
}

.division-frame__subdivision span {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.25rem;
  padding: 0 0.5rem 0;
  margin: 0 0 0.55rem 0;

  flex: none;
  order: 1;
  flex-grow: 1;

  /* background-color: var(--stak-dark-gray); */
  border-color: var(--stak-dark-gray);
  border-width: 0.1rem;
  border-style: solid;
  border-top: none;
  border-right: none;
  border-left: none;

  width: auto;

  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.25rem;
}

.division-frame__items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.25rem 0 2rem;
  gap: 0.25rem;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}
